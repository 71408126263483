import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaYoutube, FaMedium } from "react-icons/fa";
const logoUrl = <img src="/rs_w.png" alt="Digital Agency" />;

const SocialShare = [
	{ Social: <FaYoutube />, link: "https://www.youtube.com/@Recoverysol" },
	{ Social: <FaMedium />, link: "https://medium.com/@Recoverysol" },
];

class Footer extends Component {
	render() {
		return (
			<React.Fragment>
				<footer className="footer-area footer-style-01 bg_color--6">
					{/* Start Call to Action Area  */}
					<div className="im-call-to-action-area ptb--70 im-separator">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
									<div className="inner">
										<h2 className="text-white mb--0">Get in contact with us</h2>
										<p>
											If scammers have defrauded you, our team of professionals
											will help recover your money.
										</p>
									</div>
								</div>
								<div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
									<div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
										<a
											className="btn-default btn-large btn-border btn-opacity"
											href="/contact"
										>
											Contact Us
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Call to Action Area  */}

					{/* Start Footer Area  */}
					<div className="footer-wrapper ptb--70">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-6 col-sm-6 col-12">
									<div className="ft-text">
										<div className="logo">
											<Link to="/">{logoUrl}</Link>
										</div>
										<p>Copyright © {new Date().getFullYear()}</p>
									</div>
								</div>

								{/* Start Single Widget  */}
								<div className="col-lg-2 col-xl-2 offset-xl-1 col-md-6 col-sm-6 col-12 mt_mobile--40">
									<div className="footer-link">
										<h4>Quick Link</h4>
										<ul className="ft-link">
											<li>
												<Link to="/contact">contact</Link>
											</li>
											<li>
												<Link to="/about">About</Link>
											</li>
											<li>
												<Link to="/blog">Our Blog</Link>
											</li>
											<li>
												<Link to="/privacy">Privacy Policy</Link>
											</li>
											<li>
												<Link to="/terms">Terms & Conditions</Link>
											</li>
										</ul>
									</div>
								</div>
								{/* End Single Widget  */}

								{/* Start Single Widget  */}
								<div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
									<div className="footer-link">
										<h4>Services</h4>
										<ul className="ft-link">
											<li>
												<Link to="/recovery">Asset & Fund Recovery</Link>
											</li>
											<li>
												<Link to="/tracing">Crypto Asset Tracing</Link>
											</li>
											<li>
												<Link to="/investigation">Private Investigation</Link>
											</li>
											<li>
												<Link to="/analysis">Invesment Fraud Analysis</Link>
											</li>
										</ul>
									</div>
								</div>
								{/* End Single Widget  */}

								{/* Start Single Widget  */}
								<div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
									<div className="footer-link">
										<h4>Say Hello</h4>
										<ul className="ft-link">
											<li>
												Email:{" "}
												<a href="mailto:contact@recoverysol.org">
													Contact@recoverysol.org
												</a>
											</li>
											<li>
												Email:{" "}
												<a href="mailto:legal@recoverysol.org">
													Legal@recoverysol.org
												</a>
											</li>
											<li>
												Phone: <a href="tel:+19379578479">+1 (937) 957 8479</a>
											</li>
										</ul>

										<div className="social-share-inner mt--20">
											<ul className="social-share social-style--2 d-flex justify-content-start liststyle">
												{SocialShare.map((val, i) => (
													<li key={i}>
														<a href={`${val.link}`}>{val.Social}</a>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
								{/* End Single Widget  */}
							</div>
						</div>
					</div>
					{/* End Footer Area  */}
				</footer>
			</React.Fragment>
		);
	}
}
export default Footer;
